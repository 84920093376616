import {Link} from "react-router-dom";


function NavBar() {
  return (
    <div className="App">
      <header>

        <div className="header-title">Drobnosti ze dřeva</div>
      <nav className="nav">
        <Link to="/">
          <img className="logo" src="/images/logo.png" alt="" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/vsechnyprodukty">Moje výrobky</Link></li>
          {/* <li><Link to="/allproducts">Recenze</Link></li>
          <li><Link to="/allproducts">Moje výrobky</Link></li> */}

        </ul>
        <Link to={"/kontakt"} className="nav-contact">Kontakt</Link>
      </nav>
    </header>
    </div>
  );
}

export default NavBar;
