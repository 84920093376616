import { BackButton } from '../../components/BackButton';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { Link } from 'react-router-dom';
import storeItems from "../../data/ChristmasDecoration.json"


export function ChristmasCandle() {

  let selectedItem = storeItems.find(item => item.id === "CH-2" );


  return (
    <div className="App">
      <NavBar/>
      <BackButton/>


      <div className="product-info-section">

      <div className="product-info-img">
            <img 
            style={{width:"500px", height:"500px", objectFit:"cover"}} 
            src={selectedItem.imgUrl[0]} 
            alt="" />
            <small>Ilustrační obrázek</small>
          </div>

            <div className="product-info">
            <h2>{selectedItem.name}</h2>
            <Link to={selectedItem.categoryLink}><span>Kategorie: {selectedItem.category}</span></Link>
            <p>{selectedItem.description}</p>
            <p className="price">{selectedItem.price}</p>
            <h3><span>ID:</span> {selectedItem.id}</h3>
            <Link to={"/check-out"} className='product-info-button'>Mám zájem</Link>
        </div>
        /</div>
        <div className="other-images">

          <img 
            style={{width:"300px", height:"300px", objectFit:"cover"}} 
            src={selectedItem.imgUrl["1"]}
            alt="" />
        
        </div>

      <Footer/>
    </div>
  );
}
