

function TrustFactor() {

  return (
    <div className="App">
      <div className="trust-section">


      <div className="image-section">
        <img 
        style={{width:"600px" , height:"400px" , position:"relative", objectFit:"cover" }} 
        src="/images/pila.jpg" alt="" 
        />
      </div>

      <div className="text-section ">
      <p>Dřevo mi učarovalo svou vůní, krásou a originalitou. <br />
          Neustále překonávám překážky, které mi život dává  <br />
          a práce se dřevem mi pomáhá jít dál s hlavou vztyčenou... <br /></p>
      </div>
      </div>

      <div className="trust-section-1">


      <div className="image-section">
        <img   
        style={{width:"600px" , height:"400px" , position:"relative", objectFit:"cover" }} 
        src="/images/panamaria.jpg" alt="" 
        />
      </div>

      <div className="text-section-1 ">
      <p>Mou snahou je využít každý kousek dřeva. <br />
          Nepoužívám překližky a ani dřevotřísku, když to není  <br />
          záměr. Každý kousek je originál, který lze získat jen  <br />
          ruční prací. Netvořím na kvantitu, ale kvalitu  <br />
          a snažím se z nedostatků (suky, odchylky v barvě,  <br />
          léta, úlomky...) udělat přednost, tak aby přinášela <br />
          radost všem. K ošetření používám vosky a oleje, když barvu, <br />
          tak jenom zdravotně nezávadnou pro děti a potraviny. </p> 
      </div>
      </div>
    </div>
  );
}

export default TrustFactor;
