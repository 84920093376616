import { Link } from "react-router-dom";



function Footer() {
  return (
    <div className="App">
      <footer className="footer">
          <ul style={{listStyle:"none"}}>
            <div>
              <li>Miloš Tecl</li>
              <li>tel.: +420 608 301 128</li>
              <li>e-mail: info@drobnostizedreva.cz</li>
            </div>
            <div className="f-link">
                <Link to={"/vsechnyprodukty"}>Všechny produkty</Link>
                <Link to={"/kontakt"}>Kontakt</Link>
                <Link to={"/"}>Hlavní stránka</Link>
            </div>
          </ul>
          <div className="footer-logo">
            <img src="/images/logo.png" alt="" />
          </div>
      </footer>
    </div>
  );
}

export default Footer;


