import NavBar from '../components/NavBar';
import Footer from "../components/Footer"
import {useRef} from 'react'
import emailjs from '@emailjs/browser';
import { ContactForm } from '../components/ContactForm';
import  { BackButton } from '../components/BackButton';

export function Contact() {

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_92lakd4', 'template_54su6xv', form.current, 'hGWLZNVsD6LefOi_l')
      .then((result) => {
          console.log(result.text);
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });

  };

  return (
    <div className="App">
      <NavBar/>
      <BackButton/>
      <div className="contact ">
        <h1>Kontakt</h1>
        <div className="contact-info">
          <ul style={{listStyle:"none"}}>
            <li>Miloš Tecl</li>
            <li>tel.: +420 608 301 128</li>
            <li>e-mail: info@drobnostizedreva.cz</li>
            <li>adresa: Broumovská 98</li>
            <li>54701 Náchod - Malé Poříčí</li>
          </ul>
          <ul style={{listStyle:"none"}}>
            <li>Pokud máte nějaký problém nebo dotaz <br /> k produktům, neváhejte mě kontaktovat</li>
          </ul>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

