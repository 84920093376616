import { Link } from "react-router-dom";
import Badges from "./Badges";
import TrustFactor from "./TrustFactor"
import { Dropdown, DropdownButton } from "react-bootstrap";
import { StoreItem } from "./StoreItem"
import storeItems from "../data/SectionProducts.json"

function Section() {

  return (
    <div className="App">
     <section className="section d-grid justify-content-center ">
              <DropdownButton
              id="dropdown-button-dark-example2"
              variant="secondary"
              title="Kategorie"
              className="pc-none"
              data-bs-theme="dark"
            >
              <Dropdown.Item href="/vsechnyprodukty" active>Všechny produkty</Dropdown.Item>
              <Dropdown.Item href="/vazy">Vázy</Dropdown.Item>
              <Dropdown.Item href="/svicny">Svícny</Dropdown.Item>
              <Dropdown.Item href="/privesky">Přívěšky</Dropdown.Item>
              <Dropdown.Item href="/nausnice">Naušnice</Dropdown.Item>
              <Dropdown.Item href="/kabelky">Kabelky</Dropdown.Item>
              <Dropdown.Item href="/kvetinace">Květináče</Dropdown.Item>
              <Dropdown.Item href="/budky">Budky</Dropdown.Item>
              <Dropdown.Item href="/vanocnidekorace">Vánoční Dekorace</Dropdown.Item>
              <Dropdown.Item href="/velikonocnidekorace">Velikonoční Dekorace</Dropdown.Item>
              <Dropdown.Item href="/ostatni">Ostatní</Dropdown.Item>
              {/* <Dropdown.Divider /> */}
            </DropdownButton>
      <div className="card-section ">

      <div className="product-section">
        {storeItems.map(item => (
          <div key={item.id}>
            <StoreItem {...item} />
          </div>
        ))}
        </div>
      </div>

      </section>


               <div className="product-o-section">
                  <div className="product-o-links  mobile-none">
                    <li><Link to={"/vsechnyprodukty"}>Všechny produkty</Link></li>
                    <li><Link to={"/vazy"}>Vázy</Link></li>
                    <li><Link to={"/svicny"}>Svícny</Link></li>
                    <li><Link to={"/privesky"}>Přívěšky</Link></li>
                    <li><Link to={"/nausnices"}>Naušnice</Link></li>
                    <li><Link to={"/kabelky"}>Kabelky</Link></li>
                    <li><Link to={"/kvetinace"}>Květináče</Link></li>
                    <li><Link to={"/budky"}>Budky </Link></li>
                    <li><Link to={"/vanocnidekorace"}>Vánoční Dekorace</Link></li>
                    <li><Link to={"/velikonocnidekorace"}>Velikonoční Dekorace</Link></li>
                    <li><Link to={"/ostatni"}>Ostatní</Link></li>
                  </div>

              </div>


      <Badges/>
      <TrustFactor/>
    </div>
  );
}

export default Section;
