import NavBar from '../components/NavBar';
import { Link } from 'react-router-dom';
// import { Items } from '../ProductItems/Items';
import {Card} from "react-bootstrap"
import Footer from '../components/Footer';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import storeItems4 from "../data/Earrings.json"
import storeItems5 from "../data/EasterDecorations.json"
import storeItems6 from "../data/Pendants.json"
import { StoreItem } from "../components/StoreItem"


export function AllProductsTwo() {

  return (
    <div className="App">
      <NavBar/>
            <div className="product-o-section">
              <h1 className='product-title'>Moje výrobky </h1>
            <DropdownButton
              id="dropdown-button-dark-example2"
              variant="secondary"
              title="Kategorie"
              className=""
              data-bs-theme="dark"
            >
              <Dropdown.Item href="/vsechnyprodukty" active>Všechny produkty</Dropdown.Item>
              <Dropdown.Item href="/vazy">Vázy</Dropdown.Item>
              <Dropdown.Item href="/svicny">Svícny</Dropdown.Item>
              <Dropdown.Item href="/privesky">Přívěšky</Dropdown.Item>
              <Dropdown.Item href="/nausnice">Naušnice</Dropdown.Item>
              <Dropdown.Item href="/kabelky">Kabelky</Dropdown.Item>
              <Dropdown.Item href="/kvetinace">Květináče</Dropdown.Item>
              <Dropdown.Item href="/budky">Budky</Dropdown.Item>
              <Dropdown.Item href="/vanocnidekorace">Vánoční Dekorace</Dropdown.Item>
              <Dropdown.Item href="/velikonocnidekorace">Velikonoční Dekorace</Dropdown.Item>
              <Dropdown.Item href="/ostatni">Ostatní</Dropdown.Item>
              {/* <Dropdown.Divider /> */}
            </DropdownButton>

            </div>

             <div className="product-section">


             {storeItems4.map(item => (
              <div key={item.id}>
                <StoreItem {...item} />
              </div>
               ))}
             {storeItems5.map(item => (
              <div key={item.id}>
                <StoreItem {...item} />
              </div>
               ))}
             {storeItems6.map(item => (
              <div key={item.id}>
                <StoreItem {...item} />
              </div>
               ))}
         


            </div>

            <div className="next-page">
              <div className="next-link">
                <div className="next-button">
                  <Link to={"/vsechnyprodukty"}>
                    <svg className='rotate' xmlns="http://www.w3.org/2000/svg" width="25.794" height="25.783" viewBox="0 0 66.794 52.783">
                      <path id="Path_53" data-name="Path 53" d="M40.4,0,32.5,7.9,45.4,20.8H0V31.98H45.4l-12.9,12.9,7.9,7.9L66.794,26.392Z" transform="translate(0)" fill="#8b6a4b"/>
                    </svg>
                  </Link>
                  <Link to={"/vsechnyprodukty/3"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25.794" height="25.783" viewBox="0 0 66.794 52.783">
                      <path id="Path_53" data-name="Path 53" d="M40.4,0,32.5,7.9,45.4,20.8H0V31.98H45.4l-12.9,12.9,7.9,7.9L66.794,26.392Z" transform="translate(0)" fill="#8b6a4b"/>
                    </svg>
                  </Link>
                </div>
                <div className="next-numbers">
                  <Link to={"/vsechnyprodukty"}>1</Link>
                  <Link id='active'>2</Link>
                  <Link to={"/vsechnyprodukty/3"}>3</Link>
                  {/* <Link to={"/vsechnyprodukty/4"} >4</Link> */}
                </div>
              </div>

            </div>

        <Footer/>
    </div>
  );
}

