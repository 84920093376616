import {Card} from "react-bootstrap"
import { Link } from "react-router-dom"



export function StoreItem({name, imgUrl, link}) {
    return (
      <div className="product">
      <Link to={link} className="card-body">
         <Card.Img 
         src={imgUrl[0]} 
         height="300px"
         style={{objectFit:"cover"}} 
         />
         </Link>
          <div className="card-title">
                <span><Link to={link}>{name}</Link></span>
          </div>
      </div>
       )
}
