import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Contact } from './pages/Contact';
import { CheckOut} from './pages/CheckOut';

import AllProducts from './ProductFilterPages/AllProducts';
import VaseProducts from './ProductFilterPages/VaseProducts';
import CandlesticksProducts from './ProductFilterPages/CandlesticksProducts'
import EarringsProducts from './ProductFilterPages/EarringsProducts'
import PendantsProducts from './ProductFilterPages/PendantsProducts';
import PursesProducts from './ProductFilterPages/PursesProducts';
import PotsProducts from './ProductFilterPages/PotsProducts';
import OthersProducts from './ProductFilterPages/OthersProducts';
import BirdhousesProducts from './ProductFilterPages/BirdhousesProducts';
import EasterProducts from './ProductFilterPages/EasterProducts';
import ChristmasProducts from './ProductFilterPages/ChristmasProducts';


import VaseRabbit from './ProductPages/Vases/V-2'
import { VaseSpiral } from './ProductPages/Vases/V-1';
import { VaseHeart } from './ProductPages/Vases/V-3';
import { CandleCouple } from './ProductPages/Candlesticks/C-1';
import { PendantElephant } from './ProductPages/Pendants/PE-1';
import { PendantCat } from './ProductPages/Pendants/PE-2';
import { PendantPaw } from './ProductPages/Pendants/PE-3';
import { PendantHeartWArrow } from './ProductPages/Pendants/PE-4';
import { PendantHeartWHole } from './ProductPages/Pendants/PE-5';
import { PendantHeartWDia } from './ProductPages/Pendants/PE-6';
import { PendantCatSiluet } from './ProductPages/Pendants/PE-7';
import { PendantBeads } from './ProductPages/Pendants/PE-8';
import { PendantHorse } from './ProductPages/Pendants/PE-9';
import { PendantScaredCat } from './ProductPages/Pendants/PE-10';
import { PendantPuzzle } from './ProductPages/Pendants/PE-11';
import { EarringCatSiluet } from './ProductPages/Earrings/E-1';
import { EarringWings } from './ProductPages/Earrings/E-2';
import { EarringWingsFull } from './ProductPages/Earrings/E-3';
import { EarringFishSkeleton } from './ProductPages/Earrings/E-4';
import { PurseWood } from './ProductPages/Purses/PU-2';
import { PurseWhite } from './ProductPages/Purses/PU-1';
import { PotGround } from './ProductPages/FlowerPots/PO-1';
import { HouseFood } from './ProductPages/BirdHouses/BH-1';
import { HouseStick } from './ProductPages/BirdHouses/BH-3';
import { HouseFoodWall } from './ProductPages/BirdHouses/BH-2';
import Groot from './ProductPages/Pendants/PE-12';
import { ChristmasReindeer } from './ProductPages/ChristmasDecoration/CH-1';
import { ChristmasCandle } from './ProductPages/ChristmasDecoration/CH-2';
import { EasterBunny } from './ProductPages/EasterDecorations/EA-1';
import { HouseFoodCenter } from './ProductPages/BirdHouses/BH-4';
import { HouseFoodCurve } from './ProductPages/BirdHouses/BH-5';
import { HouseClosed } from './ProductPages/BirdHouses/BH-6';
import { HouseClosedGreen } from './ProductPages/BirdHouses/BH-7';
import { HouseClosedBrown } from './ProductPages/BirdHouses/BH-8';
import { EasterTwoBunny } from './ProductPages/EasterDecorations/EA-2';
import { EasterBunnyBack } from './ProductPages/EasterDecorations/EA-3';
import { ChristmasCribCandle} from './ProductPages/ChristmasDecoration/CH-4';
import { OtherRing } from './ProductPages/Others/O-1';
import { OtherWoman } from './ProductPages/Others/O-2';
import { OtherFish } from './ProductPages/Others/O-3';
import { OtherCigaretSlim } from './ProductPages/Others/O-4';
import { OtherCigaret } from './ProductPages/Others/O-5';



import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AllProductsTwo } from './ProductFilterPages/Allproducts2';
import { AllProductsThree } from './ProductFilterPages/AllProducts3';
import { OtherFlower } from './ProductPages/Others/O-6';
import { AllProductsFour } from './ProductFilterPages/AllProducts4';
import { EasterBunnyMan } from './ProductPages/EasterDecorations/EA-5';
import { OtherBee, VaseRose } from './ProductPages/Others/O-7';
import { EasterBunnyConnected } from './ProductPages/EasterDecorations/EA-4';
import { ChristmasReindeerCandle } from './ProductPages/ChristmasDecoration/CH-3';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/vsechnyprodukty",
    element: <AllProducts/>,
  },
  {
    path: "/vsechnyprodukty/2",
    element: <AllProductsTwo/>,
  },
  {
    path: "/vsechnyprodukty/3",
    element: <AllProductsThree/>,
  },
  {
    path: "/vsechnyprodukty/4",
    element: <AllProductsFour/>,
  },
  {
    path: "/vazy",
    element: <VaseProducts/>,
  },
  {
    path: "/svicny",
    element: <CandlesticksProducts/>,
  },
  {
    path: "/privesky",
    element: <PendantsProducts/>,
  },
  {
    path: "/nausnice",
    element: <EarringsProducts/>,
  },
  {
    path: "/kabelky",
    element: <PursesProducts/>,
  },
  {
    path: "/kvetinace",
    element: <PotsProducts/>,
  },
  {
    path: "/budky",
    element: <BirdhousesProducts/>,
  },
  {
    path: "/ostatni",
    element: <OthersProducts/>,
  },
  {
    path: "/vanocnidekorace",
    element: <ChristmasProducts/>,
  },
  {
    path: "/velikonocnidekorace",
    element: <EasterProducts/>,
  },


  


  {
    path: "/kontakt",
    element: <Contact/>,
  },
  {
    path: "/check-out",
    element: <CheckOut/>,
  },
  

  
                               // Vases
  
  {
    path: "/vazy/V-2",
    element: <VaseRabbit/>,
  },
  {
    path: "/vazy/V-1",
    element: <VaseSpiral/>,
  },
  {
    path: "/vazy/V-3",
    element: <VaseHeart/>,
  },

  
  
                               //Candle Sticks
  
  {
    path: "/svicny/C-1",
    element: <CandleCouple/>,
  },


                               //Pendants
  
  {
    path: "/privesky/PE-1",
    element: <PendantElephant/>,
  },
  {
    path: "/privesky/PE-2",
    element: <PendantCat/>,
  },
  {
    path: "/privesky/PE-3",
    element: <PendantPaw/>,
  },
  {
    path: "/privesky/PE-4",
    element: <PendantHeartWArrow/>,
  },
  {
    path: "/privesky/PE-5",
    element: <PendantHeartWHole/>,
  },
  {
    path: "/privesky/PE-6",
    element: <PendantHeartWDia/>,
  },
  {
    path: "/privesky/PE-7",
    element: <PendantCatSiluet/>,
  },
  {
    path: "/privesky/PE-8",
    element: <PendantBeads/>,
  },
  {
    path: "/privesky/PE-9",
    element: <PendantHorse/>,
  },
  {
    path: "/privesky/PE-10",
    element: <PendantScaredCat/>,
  },
  {
    path: "/privesky/PE-11",
    element: <PendantPuzzle/>,
  },
  {
    path: "/privesky/PE-12",
    element: <Groot/>,
  },

                              //Earrings
  
  {
    path: "/nausnice/E-1",
    element: <EarringCatSiluet/>,
  },
  {
    path: "/nausnice/E-2",
    element: <EarringWings/>,
  },
  {
    path: "/nausnice/E-3",
    element: <EarringWingsFull/>,
  },
  {
    path: "/nausnice/E-4",
    element: <EarringFishSkeleton/>,
  },


                              //Purse
  
  {
    path: "/kabelky/PU-1",
    element: <PurseWhite/>,
  },
  {
    path: "/kabelky/PU-2",
    element: <PurseWood/>,
  },


                              //Flower Pots
  
  {
    path: "/kvetinace/PO-1",
    element: <PotGround/>,
  },


                              //Bird Houses
  
  {
    path: "/budky/BH-1",
    element: <HouseFood/>,
  },
  {
    path: "/budky/BH-2",
    element:  <HouseFoodWall/>,
  },
  {
    path: "budky/BH-3",
    element:<HouseStick/>,
  },
  {
    path: "/budky/BH-4",
    element: <HouseFoodCenter/>,
  },
  {
    path: "/budky/BH-5",
    element: <HouseFoodCurve/>,
  },
  {
    path: "/budky/BH-6",
    element: <HouseClosed/>,
  },
  {
    path: "/budky/BH-7",
    element: <HouseClosedGreen/>,
  },
  {
    path: "/budky/BH-8",
    element: <HouseClosedBrown/>,
  },

                                // Christmas Products

  {
    path: "/vanocnidekorace/CH-1",
    element: <ChristmasReindeer/>,
  },
  {
    path: "/vanocnidekorace/CH-2",
    element: <ChristmasCandle/>,
  },
  {
    path: "/vanocnidekorace/CH-3",
    element: <ChristmasReindeerCandle/>,
  },
  {
    path: "/vanocnidekorace/CH-4",
    element: <ChristmasCribCandle/>,
  },

                              //Easter Products

  {
    path: "/velikonocnidekorace/EA-1",
    element: <EasterBunny/>,
  },
  {
    path: "/velikonocnidekorace/EA-2",
    element: <EasterTwoBunny/>,
  },
  {
    path: "/velikonocnidekorace/EA-3",
    element: <EasterBunnyBack/>,
  },
  {
    path: "/velikonocnidekorace/EA-4",
    element: <EasterBunnyConnected/>,
  },
  {
    path: "/velikonocnidekorace/EA-5",
    element: <EasterBunnyMan/>,
  },
 

                                // Other Products

  {
    path: "/ostatni/O-1",
    element: <OtherRing/>,
  },                      
  {
    path: "/ostatni/O-2",
    element: <OtherWoman/>,
  },      
  {
    path: "/ostatni/O-3",
    element: <OtherFish/>,
  },                      
  {
    path: "/ostatni/O-4",
    element: <OtherCigaretSlim/>,
  },                       
  {
    path: "/ostatni/O-5",
    element: <OtherCigaret/>,
  },                      
  {
    path: "/ostatni/O-6",
    element: <OtherFlower/>,
  },                      
  {
    path: "/ostatni/O-7",
    element: <OtherBee/>,
  },                      
                 
]);





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);


reportWebVitals();
