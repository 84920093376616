import {useRef} from 'react'
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom';


export function ContactForm() {


  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_92lakd4', 'template_54su6xv', form.current, 'hGWLZNVsD6LefOi_l')
      .then((result) => {
          console.log(result.text);
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });
  };

  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  return (
    <div className="App">
          <div className="container">
            <h2>Vyplnění objednávky</h2>
            <form onSubmit={sendEmail} ref={form} className="contact-form" action="">
              <input type="text" placeholder="ID produktu" name="product_id" required />
              <button className='goback-btn-form' onClick={goBack}>Vrátit se zpět na product</button>	
              <input type="text" placeholder="Jméno a příjmení" name="user_name" required />
              <input type="tel" placeholder="+420 999 777 888" name="user_tel" required />
              <input type="text" placeholder="Město" name="user_town" required />
              <input type="text" placeholder="Adresa" name="user_address" required />
              <input type="email" placeholder="E-mail" name="user_email" required />
              {/* <input type="text" placeholder="Předmět" name="subject" required /> */}
              <textarea placeholder='Požadavky k výrobě' name="message" style={{maxHeight:"100px"}}></textarea>
              <button type="submit">Potvrdit odeslání</button>
            </form>
          </div>
    </div>


  );

}

   
