import NavBar from '../components/NavBar';
import { Link } from 'react-router-dom';
// import { EasterItems } from '../ProductItems/EasterItems';
import {Card} from "react-bootstrap"
import Footer from '../components/Footer';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { StoreItem } from "../components/StoreItem"
import storeItems from "../data/CandleSticks.json"


function CandlesticksProducts() {
  return (
    <div className="App">
      <NavBar/>
            <div className="product-o-section">
              <h1 className='product-title'>Moje výrobky </h1>
                <DropdownButton
                  id="dropdown-button-dark-example2"
                  variant="secondary"
                  title="Kategorie"
                  className=""
                  data-bs-theme="dark"
                  >
                  <Dropdown.Item href="/vsechnyprodukty" >Všechny produkty</Dropdown.Item>
                  <Dropdown.Item href="/vazy">Vázy</Dropdown.Item>
                  <Dropdown.Item href="/svicny" active>Svícny</Dropdown.Item>
                  <Dropdown.Item href="/privesky">Přívěšky</Dropdown.Item>
                  <Dropdown.Item href="/nausnice">Naušnice</Dropdown.Item>
                  <Dropdown.Item href="/kabelky">Kabelky</Dropdown.Item>
                  <Dropdown.Item href="/kvetinace" >Květináče</Dropdown.Item>
                  <Dropdown.Item href="/budky">Budky</Dropdown.Item>
                  <Dropdown.Item href="/vanocnidekorace">Vánoční Dekorace</Dropdown.Item>
                  <Dropdown.Item href="/velikonocnidekorace">Velikonoční Dekorace</Dropdown.Item>
                  <Dropdown.Item href="/ostatni">Ostatní</Dropdown.Item>
                  {/* <Dropdown.Divider /> */}
                </DropdownButton>
              </div>

                <div className="product-page-name">
                    <hr/>
                    <h2><span>-</span> Svícny</h2>
                </div>

              <div className="product-section">


        {/* <div className="product">
          <Card.Img 
          src="/images/svicen1.jpg" 
          height="300px"
          style={{objectFit:"cover"}} 
          />
          <Link to={"/svicny/C-1"} className="card-body">
            <div className="card-title">
                  <span>Pár</span>
            </div>
          </Link>
        </div> */}

        {storeItems.map(item => (
              <div key={item.id}>
                <StoreItem {...item} />
              </div>
        ))}

      </div>

      <Footer/>
    </div>
  );
}

export default CandlesticksProducts