import Carousel from 'react-bootstrap/Carousel';

function Carousels() {
  return (
    <Carousel>
      <Carousel.Item interval={5000}>
        <h2 className='carousel-h2'>Drobnosti ze dřeva</h2>  
        <img
          className="carousel d-block w-100"
          style={{height:"800px", objectFit:"cover"}}
          src="./images/privesek8.jpg"
          alt="First slide"
        />
        {/* <Carousel.Caption style={{margin:"auto 0px"}}>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <h2 className='carousel-h2'>Drobnosti ze dřeva</h2>
        <img
          className="carousel d-block w-100"
          style={{height:"800px", objectFit:"cover"}}
          src="./images/krabicka2.jpg"
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <h2 className='carousel-h2'>Drobnosti ze dřeva</h2>
        <img
          className="carousel d-block w-100"
          style={{height:"800px", objectFit:"cover"}}
          src="./images/budka1-1.jpg"
          alt="Third slide"
        />
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousels;