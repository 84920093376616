


function Badges() {
  return (
    <div className="App">
     <div className="badges-section justify-content-center ">
      <img style={{width:"300px", height:"300px"}} src="/images/rucniprace.png" alt="" />
      <img style={{width:"300px", height:"300px"}} src="/images/Originalita.png" alt="" />
      <img style={{width:"300px", height:"300px"}} src="/images/kvalita.png" alt="" />
     </div>
    </div>
  );
}

export default Badges;

