import React from "react"
import './styles/App.css';
import './styles/MobileStyles.css';
import './styles/TabletStyles.css'
import "bootstrap/dist/css/bootstrap.min.css";

import Section from './components/Section';
import NavBar from "./components/NavBar";
import Carousels from "./components/Carousel";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="App">
        <NavBar/>
        <Carousels/>
        <Section/>  
         <Footer/>
    </div>
  );
}

export default App;
