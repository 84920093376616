import { BackButton } from '../../components/BackButton';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { Link } from 'react-router-dom';


export function HouseFoodCurve() {



  return (
    <div className="App">
      <NavBar/>
      <BackButton/>

      <div className="product-info-section">

        <img 
        style={{width:"500px", height:"500px", objectFit:"cover"}} 
        src="/images/budka5-1.jpg" 
        alt="" />

        <div className="product-info">
          <h2>Ptačí krmítko </h2>
          <Link to={"/budky"}><span>Kategorie: Budky</span></Link>
          <p>Krmítko pro ptáky. Přírodní dřevo ošetřeno impregnací a voskovým olejem <br /> nebo barevnou lazůrou dle dohody.Velikost cca 20x15x25 cm</p>
          <p className='price'>od 450kč</p>
          <h3><span>ID:</span> BH-5</h3>
          <Link to={"/check-out"} className='product-info-button'>Mám zájem</Link>
        </div>
      </div>

      <div className="other-images">

        <img 
        style={{width:"300px", height:"300px", objectFit:"cover"}} 
        src="/images/budka5-2.jpg" 
        alt="" />

        <img 
        style={{width:"300px", height:"300px", objectFit:"cover"}} 
        src="/images/budka5-3.jpg" 
        alt="" />

        <img 
        style={{width:"300px", height:"300px", objectFit:"cover"}} 
        src="/images/budka5-4.jpg" 
        alt="" />

        </div>

      <Footer/>
    </div>
  );
}
