import NavBar from '../components/NavBar';
import Footer from "../components/Footer"
import { ContactForm } from '../components/ContactForm';
import  { BackButton } from '../components/BackButton';


export function CheckOut() {



  return (
    <div className="App">
      <NavBar/>
      {/* <BackButton/> */}

      <div className="check-out ">
        {/* <h1 className='check-h1'>Prosím pošlete nám e-mail s detaily</h1>
        <p className="check-p">E-mail pište tímto způsobem</p>
        <div className="check-example">
          <li className='check-ex-li'><span>Jméno:</span> Jan Moravec</li>
          <li className='check-ex-li'><span>Tel.:</span> +420 900 700 200</li>
          <li className='check-ex-li'><span>Město:</span> Jihlava</li>
          <li className='check-ex-li'><span>Ulice a číslo popisné:</span> Okresní 12</li> 
          <br />
          <li className='check-ex-li'><span>ID kód produktu:</span> PE-1</li>
          <li className='check-ex-li'><span>Požadavky k výrobě:</span> Prosím aby výrobek byl o velikosti 13x16 cm</li>
        </div>
        <h3 className='check-h3'>Prosím posílejte na e-mail: <span>info@drobnostizedreva.eu</span> </h3> */}
        <ContactForm/>
        
        <h3 className='check-h3'>Jak zjistit ID produktu?</h3>
        <div className='howto-div'>
          <img className='howto-img' src="./images/howto.png" alt="" />
        </div>


      <Footer/>
      </div>
    </div>
  );
}

